export const FirebaseConfig = {
	"projectId": "taxilos",
	"appId": "1:265832980582:web:321eaf5f43e3fcfa730da7",
	"databaseURL": "https://taxilos-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "taxilos.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyBiaqtHLnQ4LLSXHr7nP_uxmslQJUVb7xY",
	"authDomain": "taxilos.firebaseapp.com",
	"messagingSenderId": "265832980582",
	"measurementId": "G-27NM51X2N7"
};